import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/axios';  // 引入axios
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import global from './global'  // 引入通用参数及函数库
import './assets/css/general.css'  // 引入通用样式库
import './assets/css/animate.css'  // 引入动画样式库
import './assets/css/font-awesome-4.7.0/css/font-awesome.min.css'  // 引入Font Awesome 4.7.0图标字体库和CSS框架

Vue.use(ElementUI);

Vue.config.productionTip = false;

// 绑定 global（通用参数及函数库）为Vue全局变量
Vue.prototype.$global = global;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

!function writeViewPort() {
	var DESIGN_WIDTH = 750;//设计稿宽度
	var DESIGN_dpr = 1;
	var DESIGN_ROOT_FONT_SIZE = 100;//默认设计稿的html字体大小为100px
	/**
	* 2.大屏适配，设置rootFontSize
	*/
	var docWidth = document.documentElement.getBoundingClientRect().width;//获取文档宽度，或屏幕宽度。独立像素。//设置viewport后，才能正确获取该值。
	//按物理像素的宽度比例来计算根字体大小。
	var realFontSize = docWidth / (DESIGN_WIDTH / DESIGN_dpr) * DESIGN_ROOT_FONT_SIZE;
	document.documentElement.style.fontSize = realFontSize + "px";
}();