import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import store from './../store'
import global from './../global'  // 引入通用参数及函数库

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../views/home.vue'),  // lazy-loaded
	},
    {
        path: '/departments',
        name: 'departments',
        component: () => import('../views/departments.vue')  // lazy-loaded
    },
    {
        path: '/department/:id',
        name: 'department',
        component: () => import('../views/department.vue')  // lazy-loaded
    },
    {
        path: '/doctors',
        name: 'doctors',
        component: () => import('../views/doctors.vue')  // lazy-loaded
    },
    {
        path: '/doctor/:id',
        name: 'doctor',
        component: () => import('../views/doctor.vue')  // lazy-loaded
    },
    {
        path: '/packages/:id',
        name: 'packages',
        component: () => import('../views/packages.vue')  // lazy-loaded
    },
    {
        path: '/package/:id',
        name: 'package',
        component: () => import('../views/package.vue')  // lazy-loaded
    },
    {
        path: '/guideline',
        name: 'guideline',
        component: () => import('../views/guideline.vue')  // lazy-loaded
    },
    {
        path: '/insurance',
        name: 'insurance',
        component: () => import('../views/insurance.vue')  // lazy-loaded
    },
    {
        path: '/reservation',
        name: 'reservation',
        component: () => import('../views/reservation.vue')  // lazy-loaded
    },
    {
        path: '/transportation',
        name: 'transportation',
        component: () => import('../views/transportation.vue')  // lazy-loaded
    },
    {
        path: '/news_list',
        name: 'news_list',
        component: () => import('../views/news_list.vue')  // lazy-loaded
    },
    {
        path: '/news/:id',
        name: 'news',
        component: () => import('../views/news.vue')  // lazy-loaded
    },
    {
        path: '/halls',
        name: 'halls',
        component: () => import('../views/halls.vue')  // lazy-loaded
    },
    {
        path: '/hall/:id',
        name: 'hall',
        component: () => import('../views/hall.vue')  // lazy-loaded
    },
    {
        path: '/history',
        name: 'history',
        component: () => import('../views/history.vue')  // lazy-loaded
    },
    {
        path: '/facilities',
        name: 'facilities',
        component: () => import('../views/facilities.vue')  // lazy-loaded
    },
    {
        path: '/facility/:id',
        name: 'facility',
        component: () => import('../views/facility.vue')  // lazy-loaded
    },
    {
        path: '/members',
        name: 'members',
        component: () => import('../views/members.vue')  // lazy-loaded
    },
    {
        path: '/member/:id',
        name: 'member',
        component: () => import('../views/member.vue')  // lazy-loaded
    },
    {
        path: '/position',
        name: 'position',
        component: () => import('../views/position.vue')  // lazy-loaded
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 导航守卫
router.beforeEach((to, from, next) => {
    (async () => {
        // 当sessionId存在时，验证登录是否已过期
        if (store.getters.sessionId) {
            await axios.post(global.config.serverApi + '/user.php', {"action": "loginVerify", "sessionId":store.getters.sessionId }).then( function(response) {
                if (response.data.complete === true) {
                    if (response.data.userLogined !== true) {
                        store.commit('logout');
                    }
                } else {
                    throw new Error(response.data.error);
                }
            }).catch(function (error) {
                store.commit('setAlert', error.message);  // 弹出错误提示
            });
        }

        // 网页标题
        if (to.meta.title) {
            document.title = to.meta.title;
        } else {
            document.title = "北京天坛普华医院官方网站";
        }

        // 如果目标地址需要强制登录，则判断是否已登录，若没有则跳转至登陆页
        if (to.meta.mustLogin) {
            if (store.getters.userLogined) {
                next();
            }else{
                // 跳转至登陆页
                next({ name: 'login' });
            }
        } else {
            next();
        }
    })();
});

export default router
